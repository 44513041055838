
import { Link } from 'gatsby';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { Title, Twitter, Testonimal } from "../../components/Globals";
import { FaAngleDoubleDown } from "react-icons/fa";
import React, { useState } from 'react';
import Modal from 'react-modal';

const FintechPage = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

    const ref = React.createRef();
  
    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      const handleSubmit = (event) => {
        event.preventDefault();
        let headers = new Headers();
        headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
        let formData = new FormData();
        formData.append('from', event.target.email.value);
        formData.append('to', 'sandip@cymetrixsoft.com');
        formData.append('subject', 'Downloading FinTech CaseStudy');
        formData.append('text', 'Name: '+event.target.name.value);
        formData.append('text', 'Contact: '+event.target.contact.value);
        fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
          method: 'post',
          headers: headers,
          body: formData,
        })
        .then(function (data) {
          if(data.status === 200) {
         alert("CaseStudy will be opened in other tabs.");
         window.open(require('../../pdfdownload/Cymetrix-CustomerSuccessStory-BFS.pdf')) && window.open(require('../../pdfdownload/Cymetrix-CustomerSuccessStory-BFS-2.pdf'));
    
          }
        })
        .catch(function (error) {
          console.log('Request failed', error);
        });
      } 
      

    return (
      <Layout>
        <div className="fintech-background flex flex-col">
          <Helmet>
            <title>Salesforce Audit Product for ISO assessment | Cymetrix | Case Study</title>
            <meta name="description" content="Case study by Cymetrix: Audio product solution included Salesforce Force.com platform & Customer Community Cloud."/>
            <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
            <link rel="canonical" href="https://www.cymetrixsoft.com/customer-stories/audit-product-community-cloud/" />
          </Helmet>
          <h1 className=" xl:text-4xl xl:m-30 md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl text-white text-uppercase text-center">
            Salesforce Case Study For Audit Product For ISO Assessment
          </h1>

          <button class="focus:outline-none " >
            <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
          </button>
        </div>
        <div className="mx-0 flex justify-center mt-6">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3" style={{backgroundColor: '#f6f6f6'}} ref={ref}>
            <div class="text-gray-700 pb-5">
                <h1 className="text-lg  px-5 font-bold uppercase mt-5 mb-3" style={{color: '#1e407b'}}>About Cymetrix Software</h1>
                
                <p className="mt-4 px-5 text-justify">Cymetrix is a strategic <Link to="/services/salesforce-consulting" class="underline text-blue-700"> Salesforce Consulting Company</Link> with clients across the globe. We have been empowering businesses globally with offices in San Francisco & New York (USA), Mumbai (India), and Reading(UK). We have talented and certified, technical and functional consultants on the complete Salesforce range of products namely Sales Cloud, Service Cloud, Marketing Cloud, Commerce Cloud, Einstien Analytics, CPQ, Pardot, Commmunity Cloud & Mulesoft among others. We have domain expertise in Banking and Finance, Pharmaceuticals, Fintech, Manufactueing, E-commerce, Healthcare, Tech-Industry and Starups.</p>

            </div>
          </div>
        </div>

        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>Case Study</span>
        </div>
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Problem Statement</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Company wanted to develop product which enables companies to manage processes from ISO assessment point of view.</div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Platform Used</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">SSalesforce Force.com platform, Customer Community Cloud
                    <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4 my-4 ">
                      <li>Development of self servicing customer portal</li>
                      <li>Audit module</li>
                      <li>Training module</li>
                      <li>Maintenance module</li>     
                
                    </ul>
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Results</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">
                  <ul className="ml-1 list-disc text-base list-outside leading-relaxed px-4 my-4 ">
                      <li>Self Servicing portal for maintaining artifacts ready for ISO audits</li>
                      <li>Ease of auditing organization processes</li>
                      <li>Visibility into Non-Compliance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       <div className="flex flex-row items-center justify-center  mt-4 sm:ml-10">
         
             
       <div className="px-16 py-10 bg-gray-400 text-gray-700 max-w-6xl mx-auto">
                <h4 class="text-lg"><b>Unlock the power of Customer 360</b></h4>
                <p> <Link to="/contact-us" class="underline text-blue-700"> Contact us</Link> now to explore how Cymetrix can transform your business.</p>
        </div>

<Modal  isOpen={modalIsOpen} onRequestClose={()=> setModalIsOpen(false)}

  style={{

    overlay: {
      overflow:'auto',
      position: 'fixed',
      top: 50,
      left: 300,
      right: 300,
      bottom: -20,
      
    
    }
    
  }}
  
>
<h1 className="text-lg  px-5 font-bold uppercase text-center" style={{color: '#1e407b'}}>Enter Details To Download CaseStudy</h1>
<main className="bg-transparent w-full flex flex-col items-center">
        <div className="max-w-5xl mx-4 ">
        <form className="justify-center flex" onSubmit={handleSubmit}>
                <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
                
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                   
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                        Enter Your Email Id*
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="email" type="text" placeholder="" name="email" required/>
                    </div>
                </div>
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                        Enter Your Name*
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="name" type="text" placeholder="" name="name" required/>
                    </div>
                </div>
                <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                    <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="contact">
                        Enter Your Contact No.
                    </label>
                    <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-1"
                            id="contact" type="text" placeholder="" name="contact" />
                    </div>
                </div>
                <div className="-mx-3 md:flex">
                    <div className="sma:w-full">
                    
                   <button  className="sma:w-full bg-header text-white font-bold py-2 px-4 mb-3 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit" > Submit</button>

                   <button className=" sma:w-full bg-transparent text-center hover:border-blue-500 text-black text-base font-bold w-24 py-2 px-2 border border-gray-500 hover:border-transparent uppercase rounded-lg" onClick={() => setModalIsOpen(false)} >Close</button>
                    </div>
                </div>
                </div>
            </form>
        </div>
    </main>
   
</Modal>

          </div>
        <Testonimal />

      </Layout>
    );
}

export default FintechPage;